@import "src/assets/scss/abstracts/global";

.grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;

  @include breakpoint("lg-min") {
    grid-template-columns: repeat(2, 545px);
  }

  // /* Wave Color */
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/$light-blue+0,42c2b5+52,34ce93+100 */
  /* Old browsers */
  background: -moz-linear-gradient(
    10deg,
    $green-apple 20%,
    $sea-green 40%,
    $agrabah-blue 75%
  );
  // /* FF3.6-15 */
  background: -webkit-linear-gradient(
    10deg,
    $green-apple 20%,
    $sea-green 40%,
    $agrabah-blue 75%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    10deg,
    $green-apple 20%,
    $sea-green 40%,
    $agrabah-blue 75%
  );
  background-size: 350% 350%;
  animation: waveColors 8s ease infinite;
  // /* End Wave Color */
}

.footer {
  font-size: 10px;
  font-variant: small-caps;
  margin: 5px auto;

  // mobile footer
  @include breakpoint("lg-min") {
    display: none;
  }

  // .footer__logo
  &__logo {
    display: flex;
    margin: 0 auto;
    width: 27px;
    margin-bottom: 5px;
  }
}
