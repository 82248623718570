@import "../../assets/scss/abstracts/global";

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  // .header__profile
  &__profile {
    display: flex;
    flex-direction: column;
    width: 65%;
    margin-top: 75px;
  }

  // .header__img
  &__img {
    margin: 0 auto;
    width: calc(100% - 37%);

    @include breakpoint("lg-min") {
      margin: 0;
    }
  }
}

.profile-details {
  display: block;

  // .profile-details__title
  &__title {
    color: $mint-cream;
    font-weight: 600;
    margin: 20px 0 0;
    font-size: 20px;
    text-align: center;

    @include breakpoint("md-min") {
      font-size: 24px;
    }

    @include breakpoint("lg-min") {
      text-align: left;
    }
  }

  // .profile-details__body
  &__body {
    font-size: 16px;
    color: $mint-cream;
    margin: 10px 0;
    line-height: 1.2;
    text-align: center;

    @include breakpoint("lg-min") {
      text-align: left;
    }
  }

  // .profile-details__socials
  &__socials {
    display: flex;
    flex-direction: row;
    margin: 1rem auto;
    align-items: baseline;
    justify-content: center;

    @include breakpoint("lg-min") {
      justify-content: left;
    }
  }

  // .profile-details__footer
  &__footer {
    font-size: 14px;
    font-variant: all-small-caps;
    text-transform: uppercase;
    margin-top: 90px;
    justify-content: left;
    align-items: center;

    // desktop footer
    @media only screen and (max-width: 998px) {
      display: none;
    }

    // desktop footer
    @media only screen and (min-width: 999px) {
      display: flex;
    }
  }

  // .profile-details__logo
  &__logo {
    display: flex;
    width: 12px;
    height: 11px;
    margin-right: 5px;
  }
}

/*--------------------------------------------------------------
Key Frames
--------------------------------------------------------------*/

@keyframes waveColors {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
