@import "../../assets/scss/abstracts/global";

h2 {
  margin-top: 40px;
  color: $mint-cream;
  margin-bottom: 0;

  @media only screen and (max-width: 999px) {
    margin-top: 0;
    height: auto;
    display: flex;
    justify-content: center;
  }
}

.cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 100%;

  // .cards__header
  &__header {
    // .cards__header--right
    &--right {
      @media only screen and (max-width: 999px) {
        display: none;
      }
    }

    // .cards__header--down
    &--down {
      @include breakpoint("lg-min") {
        display: none;
      }
    }
  }
}

.card-list {
  margin-top: -30px;

  @include breakpoint("md-max") {
    height: auto;
    display: flex;
  }

  @include breakpoint("lg-min") {
    height: auto;
    width: fit-content;
  }

  &::before,
  &::after {
    content: ‘’;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &::-webkit-scrollbar:vertical {
    display: none;
  }

  &__items {
    display: grid;
    gap: 20px;
    margin: 50px auto 0;
    grid-template-columns: 275px;
    grid-auto-rows: 190px;

    @include breakpoint("sm-min") {
      grid-template-columns: 400px;
      grid-auto-rows: 225px;
      justify-content: center;
    }

    @include breakpoint("md-min") {
      grid-template-columns: repeat(2, 235px);
    }

    @media only screen and (min-width: 1201px) {
      grid-template-columns: repeat(2, 300px);
    }
  }
}
