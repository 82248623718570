/*--------------------------------------------------------------
Breakpoints
--------------------------------------------------------------*/
$breakpoints: (
  "xs-min": (
    max-width: 360px,
  ),
  "xs-max": (
    max-width: 375px,
  ),
  "sm-min": (
    min-width: 376px,
  ),
  "sm-max": (
    max-width: 425px,
  ),
  "md-max": (
    max-width: 767px,
  ),
  "md-min": (
    min-width: 768px,
  ),
  "lg-min": (
    min-width: 1000px,
  ),
  "lg-max": (
    max-width: 1200px,
  ),
  "xl-min": (
    min-width: 1440px,
  ),
) !default;

/*--------------------------------------------------------------
Colors
--------------------------------------------------------------*/
// White
$white: #fff;
$white-smoke: whitesmoke;

// Black
$black: #000;

// Blue
$light-blue: #59adeb;
$slate-blue: #2c4872;
$agrabah-blue: #000e3c;

// Green
$green-apple: #a1d9b4;
$teal: #42c2b5;
$sea-green: #32988a;
$mint-cream: mintcream;
$laser-green: #34ce93;
