@import "../../assets/scss/abstracts/global";

.card {
  $this: &;

  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column-reverse;
  cursor: pointer;
  border-radius: 10px;
  transform: perspective(1000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
  transform-style: preserve-3d;
  transition: 300ms ease-in-out;

  &__front {
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 10px;
    background-size: cover;
    background-position: top;
    flex-direction: column-reverse;
    box-shadow: 0 0 5px -0.5px $mint-cream;
  }

  &__front,
  &__back {
    position: absolute;
    backface-visibility: hidden;
  }

  &__back {
    color: $teal;
    height: 100%;
    display: flex;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transform: rotateY(180deg);
    background-color: $agrabah-blue;
    box-shadow: inset $teal 0 0 2px 1px;

    @include breakpoint("lg-max") {
      width: inherit;
    }
  }

  &__flip {
    --rotate-y: 180deg;
  }

  &:hover {
    --translate-y: -2px;
  }

  // .card__details
  &__details {
    background-color: $agrabah-blue;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    border-radius: 0 0 10px 10px;
    padding-top: 10px;
    border: 1px solid $agrabah-blue;

    &-hover {
      margin: 0 auto 170px;
      display: flex;
      flex-direction: column-reverse;
    }
  }

  // .card__title
  &__title {
    font-size: 22px;
    color: $teal;
    font-variant: small-caps;
    margin: 0 0 10px 15px;
    text-decoration: none;
  }

  // .card__description
  &__description {
    padding: 0 15px;
    font-size: 18px;
    text-align: center;
  }

  // .card__stack-icons
  &__stack-icons {
    color: $teal;
    font-size: 35px;
    margin-top: 30px;

    & svg {
      padding-right: 10px;
    }
  }

  // .card__link
  &__link {
    font-size: 26px;
    color: $teal;
    margin-right: 15px;
  }
}
