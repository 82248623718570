@import "../../assets/scss/abstracts/global";

.socials {
  // .socials__link
  &__link {
    color: $mint-cream;
    text-decoration: none;
    margin: 0 12px;
    font-size: 24px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: $slate-blue;
    }
  }
}
